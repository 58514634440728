
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

























































































































































































































































.simulation__title {
  font-weight: normal;

  @media screen and (max-width: 1024px) {
    margin-top: 1em;
  }
}
.details__item {
  line-height: 1em;
}
.text-orange {
  color: $c-orange;
}
.separator {
  border-bottom: 1px solid $c-gray-separator;
}
.actions-pictos {
  display: flex;

  :hover {
    cursor: pointer;
  }

  img {
    margin-right: 10px;
  }

  .actions-pictos__text {
    color: $c-orange;
    text-decoration: underline;
  }
}

.feedback {
  .message {
    display: flex;
    align-items: center;

    .success {
      color: $c-dark-green;
      display: flex;
      font-size: 2rem;
      margin-left: 5px;
    }

    .check-success__icon {
      fill: $c-dark-green;
      flex: 0 0 auto;
    }
  }
}
