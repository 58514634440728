
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        































































































































.text-orange {
  color: $c-orange;
  font-weight: bold;
}

.row-datas {
  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }
}

.actions-pictos {
  display: flex;

  :hover {
    cursor: pointer;
  }

  img {
    margin-right: 10px;
  }
}

::v-deep .panel-accordion-content {
  padding: 2em;

  @media screen and (max-width: 1024px) {
    display: flex;
  }
}

.feedback {
  .message {
    display: flex;
    align-items: center;

    .success {
      color: $c-dark-green;
      display: flex;
      font-size: 2rem;
      margin-left: 5px;
    }

    .check-success__icon {
      fill: $c-dark-green;
      flex: 0 0 auto;
    }
  }
}
